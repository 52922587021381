import gql from "graphql-tag";
import { SITE_FRAGMENT, CUSTOM_FIELD_FRAGMENT } from "../fragments";

const FETCH_SITE = gql`
${SITE_FRAGMENT}
  query Site($id: Int!) {
    site(id: $id) {
      ...site
    }
  }
`;
const FETCH_SITE_OWNER = gql`
  query Site($id: Int!) {
    site(id: $id) {
      id 
      siteOwner {
        id
        email
      }
    }
  }
`;

const FETCH_SITES_STATS = gql`
  query siteStats($id: Int!) {
    siteStats(id: $id) {
      currentBillingMonthSentEmails
      lifeTimeSentEmails
    }
  }
`;

const FETCH_USER_SITES = gql`
  query UserSites {
    sites {
      id
      contactsCount
      domain
      name
      campaignsCount
      userId
      isSiteOwner
      siteOwnerTeamManagementEligible
      membershipsCount
      indexCompletedAt
      indexStartedAt
    }
  }
`

const FETCH_SITE_SEGMENTS = gql`
	query segments ($siteId: Int!) {
    segments(siteId: $siteId) {
      id
      name
      rawContactsCount
		}
	}
`;


const FETCH_SITE_ANALYTICS = gql`
  query siteAnalytics ($id: Int!, $start: String, $end: String, $widgetId: Int, $landingPageId: Int) {
    siteAnalytics(id: $id, start: $start, end: $end, widgetId: $widgetId, landingPageId: $landingPageId) {
      events
      referrers
      totals
      loading
    }
  }
`

const FETCH_SITE_TRACKING_PIXELS = gql`
  query SiteTrackingPixels ($id: Int!) {
    site(id: $id) {
      id
      viewPixel
      subscribePixel
      pixels
    }
  }
`

const FETCH_SITE_NOTIFICATION_SETTINGS = gql`
  query SiteNotificationSettings ($id: Int!) {
    site(id: $id) {
      id
      notificationSettings
    }
  }
`

const FETCH_SITE_BROADCAST_LIMIT = gql`
  query Site ($id: Int!) {
    site(id: $id) {
      id
      broadcastLimitExceeded
    }
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
${CUSTOM_FIELD_FRAGMENT}
 query customFields ($siteId: Int!) {
  customFields(siteId: $siteId) {
    ...customField
  }
 }
`

const FETCH_SITE_COLUMNS = gql`
  query Site ($id: Int!) {
    site(id: $id) {
      filteredColumns
    }
  }
`

const FETCH_SITE_DATA = gql`
  query Site ($id: Int!) {
    site(id: $id) {
      data
    }
  }
`

export { 
  FETCH_SITE, 
  FETCH_SITE_SEGMENTS,
  FETCH_USER_SITES, 
  FETCH_SITE_TRACKING_PIXELS, 
  FETCH_SITE_NOTIFICATION_SETTINGS, 
  FETCH_SITE_ANALYTICS, 
  FETCH_SITES_STATS,
  FETCH_SITE_BROADCAST_LIMIT,
  FETCH_CUSTOM_FIELDS,
  FETCH_SITE_OWNER,
  FETCH_SITE_COLUMNS,
  FETCH_SITE_DATA
}
